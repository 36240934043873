<div class="joist-configurator">
    <!-- JOISTS AND BOARDS -->
    <div class="row">
        <div class="col-lg-6">
            <div class="heading-2 section-title">Vilken dimension har virket?</div>
            <div class="button-options">
                <div class="option" [ngClass]="{'selected': joistConfiguration.boardDimension == '120x45'}"
                    (click)="selectBoardDimension('120x45')">
                    120x45mm
                </div>
                <div class="option" [ngClass]="{'selected': joistConfiguration.boardDimension == '170x45'}"
                    (click)="selectBoardDimension('170x45')">
                    170x45mm
                </div>
                <div class="option" [ngClass]="{'selected': joistConfiguration.boardDimension == '195x45'}"
                    (click)="selectBoardDimension('195x45')">
                    195x45mm
                </div>
                <div class="option" [ngClass]="{'selected': joistConfiguration.boardDimension == '220x45'}"
                    (click)="selectBoardDimension('220x45')">
                    220x45mm
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="heading-2 section-title">Hur stort är bjälklaget?</div>
            <div class="row">
                <div class="col-6 align-center">
                    <div class="heading-4">Längd (m)</div>
                    <input type="number" class="align-center" (change)="update()" (keyup)="update()" [(ngModel)]="joistConfiguration.width"
                        [ngModelOptions]="{standalone: true}" />
                </div>
                <div class="col-6 align-center">
                    <div class="heading-4">Bredd (m)</div>
                    <input type="number" class="align-center" (change)="update()" (keyup)="update()" [(ngModel)]="joistConfiguration.depth"
                        [ngModelOptions]="{standalone: true}" />
                </div>
            </div>
        </div>
    </div>
    <br />
    <hr>
    <br />


    <!-- HEAVY LOAD -->
    <div>
        <div class="heading-2 section-title">Skall trädäcket bära en större tyngd?</div>
        <div class="heading-3 section-title">Exempelvis spabad, maskiner, urnor, eller gymutrustning</div>
        <div class="button-options large">
            <div class="option" [ngClass]="{'selected': joistConfiguration.heavyLoad}" (click)="selectHeavyLoad(true)">
                Ja
            </div>
            <div class="option" [ngClass]="{'selected': !joistConfiguration.heavyLoad}"
                (click)="selectHeavyLoad(false)">
                Nej
            </div>
        </div>
    </div>
    <br />
    <hr>
    <br />

    <!-- BRACKET CONFIGURATION -->
    <div>
        <div class="heading-2 section-title">Vilka profiler vill du använda?</div>
        <div class="row options">
            <div class="col-lg-4 col-md-6">
                <div class="image-option"
                    [ngClass]="{'selected': joistConfiguration.bracketConfiguration == 'NO_BRACKETS'}"
                    (click)="selectBracketConfiguration('NO_BRACKETS')">
                    <div class="inner">
                        <div class="image-container">
                            <img [src]="globals.assetUrl('deck-no-brackets.png')" />
                        </div>
                        <div class="label-container">
                            <div class="label heading-3">Inga profiler</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="image-option"
                    [ngClass]="{'selected': joistConfiguration.bracketConfiguration == 'L_BRACKETS'}"
                    (click)="selectBracketConfiguration('L_BRACKETS')">
                    <div class="inner">
                        <div class="image-container">
                            <img [src]="globals.assetUrl('deck-l-brackets.png')" />
                        </div>
                        <div class="label-container">
                            <div class="label heading-3">L-profiler på kortsidorna</div>
                        </div>
                        <div class="option-label">
                            Rekommenderas!
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="image-option"
                    [ngClass]="{'selected': joistConfiguration.bracketConfiguration == 'U_BRACKETS'}"
                    (click)="selectBracketConfiguration('U_BRACKETS')">
                    <div class="inner">
                        <div class="image-container">
                            <img [src]="globals.assetUrl('deck-u-brackets.png')" />
                        </div>
                        <div class="label-container">
                            <div class="label heading-3">U-profiler överallt</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--
    <div class="preview" #preview>
        <div class="screw-grid">
            <table>
                <tr *ngFor="let i of createRange(this.numberOfDepthScrews); let y = index">
                    <td *ngFor="let i of createRange(this.lattices); let x = index">
                        <div class="screw" *ngIf="(x*latticeDistance) % screwDistance == 0"></div>
                    </td>
                    <td>
                        <div class="screw"></div>
                    </td>
                </tr>
            </table>
        </div>
        <div class="frame" [style.height]="height + 'px'">
            <div class="lattices">
                <div class="lath" *ngFor="let i of createRange(this.lattices)"></div>
                <div class="void" [style.width]="voidWidth + 'px'"></div>
            </div>
        </div>
    </div>
-->
</div>