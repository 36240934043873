import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BoardDimension, BracketConfiguration, IJoistConfiguration } from '../../interfaces/joist';
import { Globals } from '../../services/globals';
import { StructureType } from '../../types/customizer';



@Component({
    selector: 'joist-configurator',
    templateUrl: './joist-configurator.component.html',
    styleUrls: ['./joist-configurator.component.scss']
})
export class JoistConfiguratorComponent {
    @Input()
    structureType: StructureType;
    @Input()
    joistConfiguration: IJoistConfiguration;

    @Output()
    infoUpdated = new EventEmitter();

    lattices: number = 0;
    ratio: number = 1;
    height: number = 0;
    voidWidth: number = 0;
    numberOfDepthScrews = 0;
    latticeDistance = 0.5;

    @ViewChild('preview', { static: false })
    preview: ElementRef<HTMLElement>;

    screwDistances: { [dimension in BoardDimension]: number } = {
        "120x45": 1.20,
        "170x45": 1.75,
        "195x45": 2,
        "220x45": 2.40
    }

    constructor(public globals: Globals) {

    }

    ngAfterContentInit() {

        setTimeout(() => {
            this.update();
        }, 100)
    }

    createRange(number) {
        return new Array(number);
    }

    selectBoardDimension(dimension: BoardDimension) {
        this.joistConfiguration.boardDimension = dimension;
        this.update();
    }

    selectBracketConfiguration(configuration: BracketConfiguration) {
        this.joistConfiguration.bracketConfiguration = configuration;
        this.update();
    }

    selectHeavyLoad(value: boolean) {
        this.joistConfiguration.heavyLoad = value;
        this.update();
    }

    update() {
        /*
          console.log(this.preview);
        
          this.ratio = this.joistConfiguration.depth / this.joistConfiguration.width;
          this.height = this.preview.nativeElement.offsetWidth * this.ratio;
  
          this.lattices = Math.floor(this.joistConfiguration.width / this.latticeDistance);
  
          let voidMeters = this.joistConfiguration.width % this.latticeDistance;
          let voidFraction = voidMeters / this.joistConfiguration.width;
          this.voidWidth = this.preview.nativeElement.offsetWidth * voidFraction;
          this.numberOfDepthScrews = Math.ceil(this.joistConfiguration.depth / this.screwDistance);
  */
        let screwDistance = this.screwDistances[this.joistConfiguration.boardDimension];

        // 20cm overhang on each side
        let screwWidth = this.joistConfiguration.width - .4;
        let screwDepth = this.joistConfiguration.depth - .4;

        this.joistConfiguration.xScrews = Math.ceil(screwWidth / screwDistance) + 1;
        this.joistConfiguration.yScrews = Math.ceil(screwDepth / screwDistance) + 1;

        this.joistConfiguration.numberOfScrews = this.joistConfiguration.xScrews * this.joistConfiguration.yScrews;

        if (this.globals.debugMode) {
            console.log(`Screw distance: ${screwDistance}`);
            console.log(`Screw area: ${screwWidth}x${screwDepth}`);
            console.log(`Number of screws: ${this.joistConfiguration.xScrews}x${this.joistConfiguration.yScrews}=${this.joistConfiguration.numberOfScrews}`);
        }

        this.infoUpdated.emit(null);
    }

}
