import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { Globals } from '../../services/globals';
import { StructureType } from '../../types/customizer';



@Component({
    selector: 'contact-form',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent {
    @Input()
    structureType: StructureType;

    name: string;
    email: string;
    phone: string;
    message: string;

    sending: boolean;
    sent: boolean;

    constructor(public globals: Globals, private http: HttpClient) {

    }

    validate() {
        return this.name;
    }

    async send() {
        this.sending = true;

        try {
            await this.http.post((window as any).wc_add_to_cart_params.ajax_url + '?action=send_product_query', {
                name: this.name,
                email: this.email,
                phone: this.phone,
                message: this.message,
                structureType: this.structureType
            }, {
                withCredentials: true
            }).toPromise();
            this.sent = true;
        }
        catch (e) {

        }
    }


}
