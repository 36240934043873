import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { CustomizerComponent } from './components/customizer/customizer.component';
import { JoistConfiguratorComponent } from './components/joist-configuator/joist-configurator.component';
import { Globals } from './services/globals';

@NgModule({
  declarations: [
    CustomizerComponent,
    AppComponent,
    ContactFormComponent,
    JoistConfiguratorComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [
    Globals
  ],
  bootstrap: [CustomizerComponent]
})
export class AppModule { }
