<div class="customizer">
    <!-- STRUCTURE TYPE -->
    <div class="customizer-section">
        <div class="heading-1 section-title">Vad skall du bygga?</div>
        <div class="row options">
            <div class="col-lg-3 col-md-6">
                <div class="image-option" [ngClass]="{'selected': data.structureType == 'shed'}"
                    (click)="selectStructureType('shed')">
                    <div class="inner">
                        <div class="image-container">
                            <img [src]="globals.assetUrl('attefallshus.png')" />
                        </div>
                        <div class="label-container">
                            <div class="label heading-3">Attefallshus</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="image-option" [ngClass]="{'selected': data.structureType == 'deck'}"
                    (click)="selectStructureType('deck')">
                    <div class="inner">
                        <div class="image-container">
                            <img [src]="globals.assetUrl('tradack.png')" />
                        </div>
                        <div class="label-container">
                            <div class="label heading-3">Trädäck</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="image-option" [ngClass]="{'selected': data.structureType == 'fence'}"
                    (click)="selectStructureType('fence')">
                    <div class="inner">
                        <div class="image-container">
                            <img [src]="globals.assetUrl('staket.png')" />
                        </div>
                        <div class="label-container">
                            <div class="label heading-3">Staket</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="image-option" [ngClass]="{'selected': data.structureType == 'other'}"
                    (click)="selectStructureType('other')">
                    <div class="inner">
                        <div class="image-container">
                            <img [src]="globals.assetUrl('annat.png')" />
                        </div>
                        <div class="label-container">
                            <div class="label heading-3">Annat</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- STRUCTURE TYPE: SHED -->
    <ng-container *ngIf="data.structureType == 'shed'">
        <div class="customizer-section">
            <contact-form [structureType]="data.structureType"></contact-form>
        </div>
    </ng-container>


    <!-- STRUCTURE TYPE: DECK -->
    <ng-container *ngIf="data.structureType == 'deck'">
        <div class="customizer-section">
            <br/>
            <joist-configurator (infoUpdated)="infoUpdated()" [structureType]="data.structureType"
                [joistConfiguration]="data.joistConfiguration">
            </joist-configurator>

            <br />
            <hr>
            <br />

            <!-- INSTALLATION TOOL -->
            <div>
                <div class="heading-2 section-title">Behöver du ett installationsverktyg?</div>
                <div class="button-options large">
                    <div class="option" [ngClass]="{'selected': data.includeInstallationTool}"
                        (click)="selectInstallationTool(true)">
                        Ja
                    </div>
                    <div class="option" [ngClass]="{'selected': !data.includeInstallationTool}"
                        (click)="selectInstallationTool(false)">
                        Nej, jag har redan ett
                    </div>
                </div>
            </div>
            <br />
            <hr>
            <br />

        </div>
    </ng-container>

    <!-- STRUCTURE TYPE: FENCE -->
    <ng-container *ngIf="data.structureType == 'fence'">
        <div class="customizer-section">

        </div>
    </ng-container>


    <!-- STRUCTURE TYPE: OTHER -->
    <ng-container *ngIf="data.structureType == 'other'">
        <div class="customizer-section">
            <contact-form [structureType]="data.structureType"></contact-form>
        </div>
    </ng-container>



    <!-- SUMMARY -->
    <ng-container *ngIf="data.structureType && ['deck'].includes(data.structureType)">
        <div class="customizer-section results">
            <div class="heading-1 section-title">Du behöver:</div>
            <table>
                <tr *ngFor="let product of data.productsToAdd">
                    <td>
                        <div class="heading-5">{{product.quantity}}x {{product.name}}</div>
                    </td>
                    <td>
                        <div class="heading-5 align-right">{{globals.formatCurrency(product.quantity * product.price)}}
                        </div>
                    </td>
                </tr>
                <tr class="sum">
                    <td>
                        <div class="heading-5 align-left">Totalt:</div>
                    </td>
                    <td>
                        <div class="heading-5 align-right">{{globals.formatCurrency(getTotalPrice())}}</div>
                    </td>
                </tr>
            </table>
            <br />
            <div class="row">
                <div class="col-12 align-center">
                    <button (click)="addToCart()" [disabled]="addingToCart">Lägg i varukorg</button>
                </div>
            </div>
        </div>
        <div class="customizer-section">
            <br />
            <div *ngIf="!viewResultContactForm">
                <div class="heading-2 section-title">Fortfarande osäker på vad du behöver?</div>
                <div class="button-options large">
                    <div class="option" (click)="showResultContactForm()">
                        Ja, jag vill ha mer hjälp
                    </div>
                </div>
            </div>
            <div *ngIf="viewResultContactForm">
                <contact-form [structureType]="data.structureType"></contact-form>
            </div>
            <br />
        </div>
    </ng-container>




</div>