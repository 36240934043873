import { Component } from '@angular/core';
import { createResultProduct, ICustomizationData, IResultProductInfo } from '../../interfaces/customizer';
import { IJoistConfiguration } from '../../interfaces/joist';
import { Globals } from '../../services/globals';
import { StructureType } from '../../types/customizer';
import { HttpClient } from '@angular/common/http';



@Component({
    selector: 'markskruv-customizer',
    templateUrl: './customizer.component.html',
    styleUrls: ['./customizer.component.scss']
})
export class CustomizerComponent {

    data: ICustomizationData = {
        joistConfiguration: {
            boardDimension: '120x45',
            width: 7,
            depth: 4,
            numberOfScrews: 0,
            xScrews: 0,
            yScrews: 0,
            heavyLoad: false,
            bracketConfiguration: 'L_BRACKETS'
        },
        numberOfLBrackets: 0,
        numberOfUBrackets: 0,
        includeInstallationTool: true,
        productsToAdd: []
    };
    addingToCart: boolean;
    viewResultContactForm: boolean;
    constructor(public globals: Globals, private http: HttpClient) {
        globals.debugMode = window.location.hash.includes('debug');
    }

    selectStructureType(structureType: StructureType) {
        this.data.structureType = structureType;
    }

    selectInstallationTool(value: boolean) {
        this.data.includeInstallationTool = value;
        this.infoUpdated();
    }

    async infoUpdated() {
        switch (this.data.structureType) {
            case 'deck':
                await this.processDeckConfiguration();
                break;
            case 'fence':
                await this.processFenceConfiguration();
                break;
        }

        if (this.data.includeInstallationTool) {
            this.data.productsToAdd.push(createResultProduct(this.globals.productData.installationTool, 1));
        }

        if (this.globals.debugMode) {
            console.log(`Number of L-brackets: ${this.data.numberOfLBrackets}`);
            console.log(`Number of U-brackets: ${this.data.numberOfUBrackets}`);
        }
    }

    async processDeckConfiguration() {
        let productsToAdd: IResultProductInfo[] = [];
        switch (this.data.joistConfiguration.bracketConfiguration) {
            case 'NO_BRACKETS':
                this.data.numberOfLBrackets = 0;
                this.data.numberOfUBrackets = 0;
                break;
            case 'L_BRACKETS':
                this.data.numberOfLBrackets = Math.min(this.data.joistConfiguration.xScrews, this.data.joistConfiguration.yScrews) * 2;
                this.data.numberOfUBrackets = 0;
                break;
            case 'U_BRACKETS':
                this.data.numberOfLBrackets = 0;
                this.data.numberOfUBrackets = this.data.joistConfiguration.numberOfScrews;
                break;
        }

        if (this.data.joistConfiguration.heavyLoad) {
            productsToAdd.push(createResultProduct(this.globals.productData.roundScrew1000mm, this.data.joistConfiguration.numberOfScrews));
        } else {
            productsToAdd.push(createResultProduct(this.globals.productData.roundScrew800mm, this.data.joistConfiguration.numberOfScrews));
        }
        if (this.data.numberOfLBrackets) {
            productsToAdd.push(createResultProduct(this.globals.productData.lBracket, this.data.numberOfLBrackets));
        }
        if (this.data.numberOfUBrackets) {
            productsToAdd.push(createResultProduct(this.globals.productData.uBracket, this.data.numberOfUBrackets));
        }
        this.data.productsToAdd = productsToAdd;
    }

    getTotalPrice() {
        return this.data.productsToAdd.reduce((val, p) => val + (p.price * p.quantity), 0);
    }

    async processFenceConfiguration() {

    }

    async addToCart() {
        this.addingToCart = true;
        let items = [];
        for (let product of this.data.productsToAdd) {
            items.push({
                action: 'woocommerce_ajax_add_to_cart',
                product_id: product.id,
                product_sku: '',
                quantity: product.quantity,
                variation_id: 0,
            })
        }
        let formData = new FormData();
        formData.append('action', 'woocommerce_ajax_add_to_cart');
        formData.append('items', JSON.stringify(items));
        try {
            await this.http.post((window as any).wc_add_to_cart_params.ajax_url, formData, {
                withCredentials: true
            }).toPromise();
        }
        catch (e) {

        }


        window.location.href = '/cart';
    }

    showResultContactForm() {
        this.viewResultContactForm = true;
    }


}
