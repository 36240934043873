import { StructureType } from "../types/customizer";
import { IJoistConfiguration } from "./joist";


export const createResultProduct = (productInfo: IProductInfo, quantity: number): IResultProductInfo => {
    return Object.assign(productInfo, { quantity: quantity });
}

export interface IProductInfo {
    id: string,
    name: string,
    price: number
}

export interface IProductData {
    roundScrew800mm: IProductInfo,
    roundScrew1000mm: IProductInfo,
    uBracket: IProductInfo,
    lBracket: IProductInfo,
    installationTool: IProductInfo
}

export interface IResultProductInfo extends IProductInfo {
    quantity: number;
}


export interface ICustomizationData {
    structureType?: StructureType;
    joistConfiguration?: IJoistConfiguration;
    numberOfUBrackets: number,
    numberOfLBrackets: number,
    includeInstallationTool: boolean,
    productsToAdd: IResultProductInfo[]
}