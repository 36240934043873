<div class="contact-form">
    <div *ngIf="!sent">
        <div class="heading-2 section-title">Vi vill ge dig de bästa förutsättningarna.</div>
        <div class="heading-3 section-title">Fyll i formuläret nedan. Vi kontaktar dig och kommer gemensamt fram till den
            bästa lösningen.</div>

        <div class="row">
            <div class="col-12">
                <input class="fullwidth" type="text" [(ngModel)]="name" placeholder="Namn"
                    [ngModelOptions]="{standalone: true}" />
            </div>
            <div class="col-6">
                <input class="fullwidth" type="email" [(ngModel)]="email" placeholder="Epost"
                    [ngModelOptions]="{standalone: true}" />
            </div>
            <div class="col-6">
                <input class="fullwidth" type="tel" [(ngModel)]="phone" placeholder="Telefon"
                    [ngModelOptions]="{standalone: true}" />
            </div>
            <div class="col-12">
                <textarea class="fullwidth" [(ngModel)]="message" placeholder="Meddelande (valfritt)"
                    [ngModelOptions]="{standalone: true}"></textarea>
            </div>
            <div class="col-12 align-center">
                <button [disabled]="!validate() || sending" (click)="send()">Skicka</button>
            </div>
        </div>
    </div>

    <div *ngIf="sent">
        <div class="heading-1 section-title">
            Tack för din förfrågan!
        </div>
        <div class="heading-2 section-title">
            Vi kommer att kontakta dig så snart som möjligt.
        </div>
    </div>
</div>