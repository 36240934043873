import { Injectable, EventEmitter, Injector } from "@angular/core";
import { IProductData } from "../interfaces/customizer";

@Injectable()
export class Globals {
    productData: IProductData = (window as any).customizerProducts;
    debugMode: boolean;
    constructor() {

    }

    formatCurrency(value: number) {
        var formatter = new Intl.NumberFormat(navigator.language || navigator.languages[0], {
            style: 'currency',
            currency: 'SEK',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
        return formatter.format(value);
    }

    assetUrl(url: string) {
        return `${(window as any).assetUrl}/${url}`;
    }



}